// Theme - Colors
$color-background: #04080C;
$color-primary: #02BEFF;
$color-primary2: #0A7796;
$color-white: #FFFFFF;
$color-grey: #C3CBD7;
$color-dark-grey: #2E3A4D;

// Theme - Fonts
$font-size-default: 1rem;
$font-size-smaller: 0.6rem;
$font-size-small: 0.8rem;
$font-size-large: 1.2rem;
$font-size-largest: 1.4rem;

// Theme - Fonts Strong
$font-weight-light: 100;
$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 700;

// Theme - Font Family 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair:wght@300;500&family=Rufina:wght@400;700&display=swap');



// Theme - Spaces
$space-small: 2rem;
$space-medium: 4rem;
$space-large: 8rem;


body {
  background-color: $color-background;
  color: $color-white;
  font-size: $font-size-default;
  font-family: 'Roboto', sans-serif;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}

.ProgressiveBackground {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: fade 1s;
  ;
}

.ProgressiveBackground2 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url('./background2-hd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: fade 1s;
}

.ProgressiveBackground3 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url('./background3-hd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: fade2 1s;
}

.fogcontainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: fade 2s;
}

.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .logo {
    margin-top: -10rem;
    width: calc(12vw + 70px);
  }
}

.containerBlocks {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}

//==== Menu === //


.Menu {
  display: flex;
  width: 90%;
  margin-top: 40px;
  max-width: 950px;
  justify-content: space-between;
  font-weight: 300;
  font-size: calc(0.35vw + 14px);
  cursor: pointer;

  a {
    color: #fff;
  }

  .active {

    &:after {
      left: 40px !important;
      right: 42px !important;
      opacity: 1 !important;
    }
  }



  .MenuItem {
    position: relative;
    transition: all 0.3s;
    padding: 0 40px;
    height: 60px;
    line-height: 60px;
    text-decoration: none;


    &:hover {
      opacity: 1;

    }





    &:after {
      position: absolute;

      left: 50%;
      right: 50%;


      height: 1px;
      bottom: 10px;
      content: "";
      opacity: 0;

      background: linear-gradient(90deg, rgba(255, 255, 255, 0.17690826330532217) 0%, rgba(255, 255, 255, 0.6558998599439776) 36%, rgba(255, 255, 255, 0.6278886554621849) 63%, rgba(255, 255, 255, 0.1881127450980392) 100%);

      transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);


    }

    &:hover:after {
      left: 40px;
      right: 42px;
      opacity: 1;


    }




  }

}


/* ------------- Footer ---------------- */
.Footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $color-dark-grey;
  font-size: 11px;

  //==== Social === //
  .SocialMedia {
    width: 100px;
    padding: 10px;
    text-align: center;

    .IconsArea {
      display: flex;
      justify-content: center;

      .SocialIcon {
        width: 40px;
        height: 40px;
        background-color: $color-background;
        border-radius: 500px;
        margin: 3px;
        color: $color-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms cubic-bezier(.47, 1.64, .41, .8);
        cursor: pointer;

        img {
          transition: all 1s;
        }

        &:hover {
          transform: rotate(-20deg) scale(1.22);
          background-color: $color-primary;

          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .CreditArea {

    display: flex;
    align-items: flex-end;
    padding: 10px;

    .StampImage {
      width: 60px;
      height: auto;
    }

    .CreditText {
      width: 450px;
      text-align: right;
      margin-bottom: 10px;
      margin-right: 10px;
      color: #6d6d6d;
    }


  }


}


@media only screen and (max-width: 800px) {
  .CreditArea {
    display: none !important;
  }
}


//====Glow ===//

.ParticleGlow{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
 


  .glow1{
    position: absolute;
    width: 1200px;
    height: 1200px;

    top: 10vh;
    left: 10vw;
    border-radius: 50%;
    background-color: #ffef94e8;
    filter: blur(50px);
    mix-blend-mode: overlay;
    animation: glow1animation 5s infinite;
  }

  .glow2{
    position: absolute;
    width: 1000px;
    height: 1000px;

    bottom: -20vh;
    right: 10vw;
    border-radius: 50%;
    background-color: rgba(0, 217, 255, 0.91);
    filter: blur(50px);
    mix-blend-mode: overlay;
    animation: glow2animation 3s infinite;
  }

  @keyframes glow1animation {
    0% {
      opacity: 0;
      
    }

    50% {
      opacity: 1;
     
    }

    100%{
      opacity: 0;
   
    }
  }

  @keyframes glow2animation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100%{
      opacity: 1;
    }
  }
}



//====Stars ===//

@function randomStars($min, $max) {
  $rand: random();
  $random_range: $min +floor($rand * (($max - $min) + 1));
  @return $random_range;
}


.containerStars {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;




  .star{
    width: 3px;
    height: 3px;
    background-color: rgba(255, 233, 162, 0.647);
    border-radius: 100px;
   

    box-shadow: 0px 0px 8px #ffffff;

    $totalStars: 1000;

    @for $i from 1 through $totalStars {
      $random-x:random(100);
      $random-y:random(200);
      $durationstar:random(1000)+800;
      $delaystar: random(1000)+800;
      translate: all 2s;
      $sizestar: random(3)+1;

      &:nth-child(#{$i}) {
        position: absolute;
        width: ($sizestar+px);
        height: ($sizestar+px);
        top: ($random-x+vw) !important;
        left: ($random-y+vh) !important;
        animation: staranimation-#{$i} ($durationstar+ms) ($delaystar+ms) linear infinite;

      }

      @keyframes staranimation-#{$i} {
        0% {
          opacity: 1;
        }
  
        50% {
          opacity: 0;
        }
  
        100%{
          opacity: 1;
        }
      }
    
    
    }




   
  }


}


//==== Snow === //


@function random_range($min, $max) {
  $rand: random();
  $random_range: $min +floor($rand * (($max - $min) + 1));
  @return $random_range;
}


.containerSnow {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  


}

.snow {
  $total: 200;
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  filter: blur(1px);

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x +$random-offset;
    $random-x-end-yoyo: $random-x +($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(5300) * 0.0001;
    $fall-duration: random_range(6, 22) * 1s;
    $fall-delay: random(20) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(5000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}






/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}

#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}

#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}

#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}

#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("./fog1.png") center center/cover no-repeat transparent;
}

#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("./fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .8;
  }

  40% {
    opacity: .38;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .8;
  }

  40% {
    opacity: .38;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .8;
  }

  40% {
    opacity: .38;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .8;
  }

  40% {
    opacity: .38;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: .8;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: .8;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: .8;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@keyframes foglayer_02_opacity {
  0% {
    opacity: .8;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@keyframes foglayer_03_opacity {
  0% {
    opacity: .8;
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {

  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}

//=============== Page Book =============== //

.BooksPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadeIn 0.25s ease-in-out;

}

.blur {

  position: absolute;
  
  overflow: hidden;
  width: 100%;
  height: 100%;

  .fogcontainer{
    transform: scale(2) rotate(90deg);
    filter: blur(4px);
 
    
  }


  

}

.blur2 {

  position: absolute;
  
  overflow: hidden;
  width: 100%;
  height: 100%;
  .containerSnow{
    
    //filter: blur(1px);
    transform: scaleY(45);
    opacity: 0.2;
  }
}

.blur3 {

  position: absolute;
  
  overflow: hidden;
  width: 100%;
  height: 100%;
  .containerSnow{
    
   // filter: blur(1px);
    transform: scaleY(25);
    opacity: 0.15;
  }
}

.rainAll{
  position: absolute;
  width: 100%;
  height: 100%;

  
  background-image: url('./rainall.png');
  background-size: 100% 90%;
  background-position: 20% 0%;
  background-repeat: no-repeat;
  animation: storm 0.15s infinite linear;
  opacity: 0.5;

}

@keyframes storm {
  0% {
    background-size: cover;
   // opacity: 1;
   

  }



  100% {
    background-size: 100% 90%;
 
    //opacity: 0;

  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.96);

  }

  100% {
    opacity: 1;
    transform: scale(1);

  }
}

@keyframes fade {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;

  }
}

@keyframes fade2 {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;

  }
}



//=== simple-carousel=== //

.simple-carousel {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .slide {
    color: white;

    // padding: 20px;
    width: 100%;
    height: 80%;
    max-height: 620px;
    max-width: 1100px;
    border-radius: 8px;
    background: #262C3A;
    overflow: hidden;

    .slide1 {
      display: flex;
      height: 100%;
    }

    .subtittle {
      font-family: 'Rufina', serif;
      font-size: 12px;
    }

    .tittle {
      font-family: 'Rufina', serif;
      // text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 24px;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: $color-white;
      margin-top: 15px;
      margin-bottom: 15px;
      opacity: 18%;
    }

    .PhotoArea1 {
      width: 40%;
      height: 100%;
      background-image: url("./b1.jpg");
      background-position: center center;
      background-size: auto 80%;
      background-repeat: no-repeat;
      transition: all 1s;

      @media (max-width: 1024px) {
        display:none;
      }


      &:hover {
        //  background-size: auto 120%;
      }
    }

    .PhotoArea2 {
      width: 40%;
      height: 100%;
      background-image: url("./b2.jpg");
      background-position: center center;
      background-size: auto 80%;
      background-repeat: no-repeat;
      transition: all 1s;

      @media (max-width: 1024px) {
        display:none;
      }



      &:hover {

        // filter: saturate(1.5);
      }
    }

    .PhotoArea3 {
      width: 40%;
      height: 100%;
      background-image: url("./b3.jpg");
      background-position: center center;
      background-size: auto 80%;
      background-repeat: no-repeat;
      transition: all 1s;

      @media (max-width: 1024px) {
        display:none;
      }



      &:hover {
        //  background-size: auto 120%;
      }
    }

    .TextArea {
      width: 60%;
      height: 90%;
      padding: 20px;
      font-weight: 300;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        font-size: 17px;
        text-align: left;
        opacity: 60%;

      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .boxes {
      display: flex;
      font-size: 16px;
      opacity: 50%;
      margin-bottom: 20px;
      font-weight: 500;

      .box {
        width: 100%;
        margin: 5px;
        
        min-height: 100px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);
        justify-content: space-around;

        display: flex;
        align-items: center;
      }
    }

    .ButtonsArea {
      display: flex;
    }

  }

  button {
    background-color: #2d394700;
    cursor: pointer;
    border: none;
    border-radius: 200px;
    width: 40px;
    height: 40px;
    margin: 10px;
    background-image: url("./arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40% auto;
    transition: all 0.3s;


    &:hover {

      background-color: #2d394749;
      margin-bottom: 15px;
    }

    &:active {
      background-color: #2d394749;
      margin-bottom: 10px;
    }
  }

  .buttonRight {
    transform: rotate(180deg);
  }

  .buttonbookArea {
    width: 100%;
    margin: 5px;


    .free1 {
      background-color: #02BEFF !important;
      color: #0D3348 !important;
    }

    .free2 {
      background-color: #1BD1B2 !important;
      color: #0D3348 !important;
    }

    .free3 {
      background-color: #FFC93D !important;
      color: #0D3348 !important;
    }

    .price {
      background-color: #2D3947;
      // position: absolute;
      font-size: 14px;
      width: 80px;
      padding: 5px;
      margin: auto;
      font-weight: bold;
      border-radius: 100px;

      /*
      &::after{
        content: "";
        background-color: #2D3947;
        position: absolute;
        width: 10px;
        height: 10px;

        top:20px;
        left:70px;
        transform: rotate(45deg);

      }

      */
    }

    .buttonbook {
      width: 100%;
      border-radius: 5px;
      min-height: 40px;
      font-family: 'Rufina', serif;
      background-color: #E8E9EA;
      color: #003243;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;


    }
  }

}



.slide2 {
  background-color: #212b2a;
}

.slide3 {
  background-color: #1F132A;
}